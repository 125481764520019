<template>
  <div>
    <!-- Слайдер -->
    <div class="slider-container">
      <CustomSlider />
    </div>
  </div>
</template>

<script>
import CustomSlider from "@/components/CustomSlider.vue"; // Подключение компонента слайдера


export default {
  name: "HomePage",
  components: {
    CustomSlider
  },
};
</script>

<style scoped>
h1 {
  color: #007bff;
}

.slider-container {
  margin: 20px 0;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
</style>
