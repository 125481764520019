<template>
  <div class="interactive-page">
    <h1 class="page-title">Выберите интересующую категорию</h1>

    <div class="category-blocks">
      <!-- Используем router-link для перехода -->
      <router-link to="/ico" class="category-block">
        <h2>ICO</h2>
        <p>Информация о текущих и предстоящих ICO проектах.</p>
      </router-link>

      <router-link to="/nft" class="category-block">
        <h2>NFT</h2>
        <p>Последние новости и коллекции NFT.</p>
      </router-link>

      <router-link to="/crypto-games" class="category-block">
        <h2>Криптоигры</h2>
        <p>Обзор криптовалютных игр и проектов.</p>
      </router-link>

      <router-link to="/stock-exchanges" class="category-block">
        <h2>Активности на биржах</h2>
        <p>Обзор активностей на различных биржах.</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InteractivePage',
};
</script>

<style scoped>
.interactive-page {
  text-align: center;
  padding: 20px;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

.category-blocks {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-block {
  width: 100%;
  padding: 20px;
  margin: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background-color 0.3s;
  text-decoration: none; /* Убираем подчеркивание */
  color: inherit; /* Наследуем цвет от родителя */
}

.category-block:hover {
  transform: scale(1.05);
  background-color: #e0e0e0;
}

.category-block h2 {
  font-size: 20px;
  font-weight: bold;
}

.category-block p {
  font-size: 14px;
  color: #555;
}
</style>
