<template>
  <div class="user-info">
    <div class="username-container">
      <router-link class="nav-link user-container" to="/notes">
        <img class="user-img" src="@/assets/icons/user-solid.svg" alt="User Icon" />
        <div class="username">{{ username }}</div>
      </router-link>
    </div>
    <div class="coin-container">
      <router-link class="nav-link user-container" to="/earn">
        <img class="coin-img" src="@/assets/icons/coins-solid.svg" alt="Coins Icon" />
        <div class="coin-display">{{ coins }}</div>
      </router-link>
    </div>
  </div>

  <div class="back-button-container">
    <button class="back-button" :disabled="!previousRoute" @click="goBack">
      <img src="@/assets/icons/arrow-left-solid.svg" alt="Back" />
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    coins() {
      return this.$store.state.coins;
    },
    username() {
      return this.$store.state.username;
    },
    userPhotoUrl() {
      return this.$store.state.userPhotoUrl;
    },
    previousRoute() {
      return this.$store.state.previousRoute; // Используем состояние из Vuex
    },
  },
  methods: {
    goBack() {
      if (this.previousRoute) {
        this.$router.push(this.previousRoute.fullPath);
      } else {
        this.$router.go(-1); // Переход на предыдущую страницу
      }
    },
  },
};
</script>

<style scoped>
.user-info {
  margin: 10px;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  gap: 10%;
  align-items: baseline;
}

.username {
  font-size: 20px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 10px;
}

.coin-display {
  font-weight: bold;
  font-size: 18px;
  color: #28a745;
  margin-left: 10px;
}

.user-container {
  display: flex;
  align-items: baseline;
}

.username {
  margin-left: 10px;
}

.username-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.coin-container {
  display: flex;
  align-items: baseline;
}

.user-img {
  width: 25px;
}

.coin-img {
  width: 20px;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
}
.back-button img {
  width: 30px;
  height: 30px;
}
.back-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
