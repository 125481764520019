<template>
  <div class="referrals">
    <h2>Реферальная программа</h2>
    <p>
      Пригласите друзей в наше приложение и получите бонусы за каждого
      приглашенного!
    </p>
    <button @click="openShareDialog">Отправить реферальную ссылку</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      referralLink: "",
      copyMessage: false,
      initData: null, // Храним initData в data
    };
  },
  mounted() {
    this.initData = Telegram.WebApp.initDataUnsafe;
    
    // Проверяем, что данные инициализированы
    if (this.initData) {
      this.generateReferralLink();
      this.handleReferral();
    } else {
      // Можно добавить setTimeout для повторной попытки или слушатель события
      setTimeout(() => {
        this.initData = Telegram.WebApp.initDataUnsafe;
        this.generateReferralLink();
        this.handleReferral();
      }, 1000); // Пример задержки в 1 секунду
    }
  },
  methods: {
    openShareDialog() {
      const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(this.referralLink)}&text=${encodeURIComponent(`Присоединяйся к нам с моей реферальной ссылкой: ${this.referralLink}`)}`;
      Telegram.WebApp.openTelegramLink(shareUrl);
    },
    generateReferralLink() {
      console.log("Init data:", this.initData); // Отладка
      const userId = this.initData?.user?.id;

      if (!userId) {
        console.error("Ошибка: данные пользователя не получены.");
        return;
      }

      const referralCode = btoa(userId.toString()); // Кодируем ID в Base64
      this.referralLink = `https://t.me/cryptobrich_bot/crypto_brich?start=${referralCode}`;
      console.log("Реферальная ссылка:", this.referralLink);
    },

    handleReferral() {
      console.log("Init data:", this.initData); // Отладка

      const referralCode = this.initData?.start_param; // Получаем реферальный код из start_param
      console.log("Referral code from start_param:", referralCode);

      if (referralCode) {
        try {
          const referrerChatId = atob(referralCode); // Декодируем Base64
          console.log("Decoded referrerChatId:", referrerChatId);
          this.sendReferralData(referrerChatId);
        } catch (error) {
          console.error("Ошибка при декодировании referralCode:", error);
        }
      } else {
        console.log("Реферальный код отсутствует в start_param.");
        console.log("URL:", window.location.href); // Логируем текущий URL для проверки
      }
    },

    sendReferralData(referrerChatId) {
      const initData = Telegram.WebApp.initDataUnsafe;
      const userId = initData?.user?.id;

      if (!userId) {
        console.error("Ошибка: данные пользователя не получены.");
        return;
      }

      const payload = {
        chatId: userId,
        referrerChatId: referrerChatId,
      };

      // Используем fetch для отправки данных
      fetch("https://api.crytech.pro/invite_user", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            console.log("Пользователь успешно приглашен и монеты начислены");
          } else {
            console.log("Ошибка при обработке приглашения:", data.message);
          }
        })
        .catch((error) => {
          console.error("Ошибка при отправке данных на сервер:", error);
        });
    },
  },
};
</script>



<style scoped>
.referrals {
  text-align: center;
  margin-top: 20px;
}

.referrals button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.referrals button:hover {
  background-color: #0056b3;
}

.referral-link {
  margin-top: 20px;
  text-align: left;
}

.referral-link input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
  background-color: #f9f9f9;
}
</style>
