<template>
  <div class="leaderboard">
    <h1>Рейтинг</h1>

    <!-- Информация о текущем пользователе -->
    <div v-if="currentUser" class="current-user">
      <h2 class="current-user-name">{{ currentUser.username }}</h2>
      <h3>
        <span class="current-user-rating">{{ currentUser.rank || 'Не определено' }}</span>
        <span> - в топ-100 пользователей</span>
      </h3>
      <p><span>Количество монет:</span> {{ currentUser.coins || 0 }}</p>
    </div>
    <div v-else>
      <p>Информация о текущем пользователе не найдена.</p>
    </div>

    <!-- Таблица с рейтингом -->
    <table v-if="leaderboard.length" class="table">
      <thead>
        <tr>
          <th>Место</th>
          <th>Пользователь</th>
          <th>Монеты</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(user, index) in leaderboard"
          :key="index"
          :class="{ 'highlight-row': user.chat_id === currentChatId }"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.coins }}</td>
        </tr>
      </tbody>
    </table>
    <p v-else>Данные о рейтинге загружаются или недоступны.</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leaderboard: [], // Топ-100 пользователей
      currentChatId: null, // Chat ID текущего пользователя
      currentUser: null, // Данные о текущем пользователе
    };
  },
  mounted() {
    this.fetchUserInfo();
  },
  methods: {
    async fetchUserInfo() {
      try {
        // Получаем initData от Telegram WebApp
        const initData = Telegram.WebApp.initDataUnsafe;
        const user = initData?.user;

        if (user) {
          this.currentChatId = user.id; // Устанавливаем chat_id
          console.log(typeof(this.currentChatId));

          // Отправляем запрос на бэкенд
          const response = await fetch("https://api.crytech.pro/get_user_info", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ chat_id: this.currentChatId }),
          });

          const data = await response.json();
          console.log(data);

          if (data.success) {
            this.currentUser = {
              username: data.username,
              coins: data.coins,
              rank: data.rank,
            };
            this.leaderboard = data.top_users; // Получаем топ-100 пользователей
          } else {
            console.error("Ошибка получения данных:", data.message);
          }
        } else {
          console.error("Данные пользователя недоступны через Telegram.");
        }
      } catch (error) {
        console.error("Ошибка при получении данных о пользователе:", error);
      }
    },
  },
};
</script>





<style scoped>
.leaderboard {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.current-user {
  margin-bottom: 20px;
  background-color: #e3f2fd;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th, .table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 1.1em;
}

.table th {
  background-color: #4CAF50;
  color: white;
}

.table td {
  background-color: #fff;
}

.table tr:hover {
  background-color: #f2f2f2;
  transition: background-color 0.3s ease;
}

.highlight-row {
  background-color: #e0f7fa;
  font-weight: 900;
}

.current-user-name { 
  font-weight: 900;
}

.current-user-rating {
  font-weight: 900;
}


@media (max-width: 768px) {
  .table {
    font-size: 0.6em;
  }
}
</style>
