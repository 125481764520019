import { createStore } from 'vuex';

export default createStore({
  state: {
    coins: 0,
    username: "",
    previousRoute: null, // Добавляем состояние для предыдущего маршрута
  },
  mutations: {
    setCoins(state, coins) {
      state.coins = coins;
    },
    setUserInfo(state, { username }) {
      console.log("Setting user info:", username);
      state.username = username;
    },
    setPreviousRoute(state, route) {
      state.previousRoute = route; // Мутация для сохранения предыдущего маршрута
    },
  },
  actions: {
    async fetchUserInfo({ commit }, chatId) {
      try {
        const response = await fetch("https://api.crytech.pro/get_user_coins", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ chat_id: chatId }),
        });
        const data = await response.json();
        console.log("Server Response:", data); // Логируем ответ сервера

        if (data.success) {
          console.log("Received username:", data.username); // Логируем username

          commit("setCoins", data.coins);
          commit("setUserInfo", {
            username: data.username || 'Default Username', // Если нет username, устанавливаем значение по умолчанию
          });
        } else {
          console.error("Ошибка получения информации о монетах:", data.message);
        }
      } catch (error) {
        console.error("Ошибка при обновлении данных о монетах:", error);
      }
    },
  },
});
