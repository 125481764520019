import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import HomePage from '../components/HomePage.vue';
import ICOList from '../components/ICOList.vue';
import NFTList from '../components/NFTList.vue';
import CryptoGameList from '../components/CryptoGameList.vue';
import StockExchangesList from '../components/StockExchangesList.vue';
import Coin from '../components/Coin.vue';
import Rating from '../components/Rating.vue';
import InteractiveList from '../components/IntaractiveList.vue';
import Earn from '../components/Earn.vue';
import NotesPage from '../components/NotesPage.vue';
import SponsorsPage from '../components/SponsorsPage.vue';
import PostDetail from '../components/PostDetail.vue';

const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/ico', name: 'ICOList', component: ICOList },
  { path: '/nft', name: 'NFTList', component: NFTList },
  { path: '/crypto-games', name: 'CryptoGameList', component: CryptoGameList },
  { path: '/stock-exchanges', name: 'StockExchangesList', component: StockExchangesList },
  { path: '/coins', name: 'Coin', component: Coin },
  { path: '/rating', name: 'Rating', component: Rating },
  { path: '/interactive', name: 'InteractiveList', component: InteractiveList },
  { path: '/earn', name: 'Earn', component: Earn },
  { path: '/notes', name: 'NotesPage', component: NotesPage },
  { path: '/sponsors', name: 'SponsorsPage', component: SponsorsPage },
  { path: '/:type/:id', name: 'PostDetail', component: PostDetail },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Сохраняем текущий маршрут как предыдущий перед переходом
  store.commit('setPreviousRoute', from);
  next();
});

export default router;