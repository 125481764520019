<template>
  <div>
    <h1>{{ post.title }}</h1>
    <div class="content" v-html="post.content"></div>

    <!-- Кнопка для добавления/удаления из заметок -->
    <button @click="toggleNote">
      {{ isInNotes ? "Удалить из заметок" : "Добавить в заметки" }}
    </button>
  </div>
</template>

<script>
import axios from "../axios";

export default {
  data() {
    return {
      post: {}, // Данные о записи
      loading: true, // Статус загрузки
      isInNotes: false, // Флаг для проверки заметок
    };
  },
  async mounted() {
    await this.fetchPostData();
    this.checkIfInNotes(); // Проверяем заметки сразу после загрузки данных
  },
  methods: {
    async fetchPostData() {
      try {
        const { type, id } = this.$route.params;
        const response = await axios.get(`${type}/${id}`);
        this.post = Array.isArray(response.data) ? response.data[0] : response.data;
      } catch (error) {
        console.error("Ошибка при загрузке данных записи:", error);
      } finally {
        this.loading = false;
      }
    },
    toggleNote() {
      const notes = JSON.parse(localStorage.getItem("notes") || "[]");

      if (this.isInNotes) {
        // Удаляем из заметок
        const updatedNotes = notes.filter((item) => item.id !== this.post.id);
        localStorage.setItem("notes", JSON.stringify(updatedNotes));
        this.isInNotes = false;
      } else {
        // Добавляем в заметки
        notes.push({
          id: this.post.id,
          type: this.$route.params.type,
          title: this.post.title,
          content: this.post.content,
        });
        localStorage.setItem("notes", JSON.stringify(notes));
        this.isInNotes = true;
      }
    },
    checkIfInNotes() {
      const notes = JSON.parse(localStorage.getItem("notes") || "[]");
      this.isInNotes = notes.some((item) => item.id === this.post.id);
    },
  },
};
</script>
