<template>
    <div class="sponsors-page">
      <h1>Спонсорство</h1>
      <p>
        Информацию о спонсорстве и плюсах, которые оно даёт, обратитесь
        <a :href="sponsorContactUrl" target="_blank" class="sponsor-link">сюда</a>.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        sponsorContactUrl: "https://t.me/Brichyha", 
      };
    },
  };
  </script>
  
  <style scoped>
  .sponsors-page {
    text-align: center;
    margin: 20px;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  .sponsor-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .sponsor-link:hover {
    text-decoration: underline;
  }
  </style>
  