<template>
  <div>
    <h1>Crypto Games List</h1> <!-- Заголовок слева -->
    <div v-if="loading">Загрузка...</div>
    <div v-else>
      <ul class="items-container">
        <li v-for="item in cryptoGamesData" :key="item.id" class="ico-item">
          <div class="item-container">
            <!-- Ссылка на страницу записи -->
            <router-link :to="`/${item.type}/${item.id}`" class="item-title">
              {{ item.title }}
            </router-link>

            <!-- Вывод изображения справа -->
            <img :src="item.thumbnail" alt="Thumbnail" v-if="item.thumbnail" class="item-thumbnail" />
          </div>


          <!-- Вывод краткого текста или контента записи -->
          <div v-html="item.excerpt" class="item-excerpt"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  data() {
    return {
      cryptoGamesData: [], // Массив данных для Crypto Games
      loading: true, // Состояние загрузки
    };
  },
  mounted() {
    this.fetchCryptoGamesData();
  },
  methods: {
    async fetchCryptoGamesData() {
      try {
        const response = await axios.get('crypto_games'); // API запрос для Crypto Games
        this.cryptoGamesData = response.data;
      } catch (error) {
        console.error('Error fetching Crypto Games data:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>


<style scoped>
/* Стили для страницы */
h1 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

dl,
ol,
ul {
  list-style-type: none;
}

.items-container {
  padding-left: 0;
}

.item-container {
  display: flex;
  justify-content: space-between;
}

/* Стили для каждого элемента списка */
.ico-item {
  justify-content: space-between;
  /* Размещение заголовка и изображения */
  background-color: #f9f9f9;
  /* Фон блока */
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Тень для красоты */
}

.item-title {
  font-size: 1.5em;
  text-decoration: none;
  color: #007bff;
  flex: 1;
}

.item-title:hover {
  text-decoration: underline;
}

.item-thumbnail {
  width: 100px;
  height: auto;
  border-radius: 5px;
}

.item-excerpt {
  font-size: 1em;
  color: #555;
  margin-top: 10px;
  width: 100%;
  /* Чтобы текст не перекрывался изображением */
}
</style>
