<template>
  <div class="earn-page">
    <h1>Заработать</h1>
    <!-- Вкладки -->
    <div class="tabs">
      <button
        v-for="tab in tabs"
        :key="tab.name"
        :class="{ active: activeTab === tab.name }"
        @click="activeTab = tab.name"
      >
        {{ tab.label }}
      </button>
    </div>
    <!-- Контент -->
    <div class="tab-content">
      <component :is="getTabComponent(activeTab)" />
    </div>
  </div>
</template>

<script>
import Referrals from "@/components/Referrals.vue";

export default {
  data() {
    return {
      activeTab: "referrals",
      tabs: [
        { name: "referrals", label: "Рефералы" },
        { name: "tasks", label: "Задания" },
        { name: "other", label: "Другое" },
      ],
    };
  },
  components: {
    Referrals,
    Tasks: { template: "<div>Задания</div>" }, // Пример заглушки
    Other: { template: "<div>Другое</div>" }, // Пример заглушки
  },
  methods: {
    getTabComponent(tabName) {
      const components = {
        referrals: "Referrals",
        tasks: "Tasks",
        other: "Other",
      };
      return components[tabName] || "div";
    },
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 15px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tabs button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.tab-content {
  margin-top: 20px;
}
</style>
