<template>
  <div>
    <h1>Мои заметки</h1>
    <div v-if="notes.length === 0">Нет сохранённых заметок</div>
    <div v-else>
      <div v-for="note in notes" :key="note.id" class="note-item">
        <h2>{{ note.title }}</h2>
        <div class="notes-content" v-html="note.excerpt"></div>
        <router-link :to="{ name: 'PostDetail', params: { type: note.type, id: note.id } }">
          Открыть запись
        </router-link>
        <button @click="removeNote(note.id)">Удалить из заметок</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notes: [], // Массив заметок
    };
  },
  mounted() {
    this.loadNotes();
  },
  methods: {
    loadNotes() {
      const storedNotes = JSON.parse(localStorage.getItem("notes") || "[]");
      this.notes = storedNotes;
    },
    removeNote(id) {
      const updatedNotes = this.notes.filter((note) => note.id !== id);
      this.notes = updatedNotes;
      localStorage.setItem("notes", JSON.stringify(updatedNotes));
    },
  },
};
</script>

<style scoped>
.note-item {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.note-item h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.note-item button {
  margin-top: 10px;
}
</style>
