<template>
    <div 
        class="custom-slider"
        @mousedown="startDragging" 
        @mousemove="onDragging" 
        @mouseup="endDragging" 
        @mouseleave="endDragging"
        @touchstart="startDragging"
        @touchmove="onDragging"
        @touchend="endDragging"
    >
        <div class="slider-wrapper" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
            <div class="slide" v-for="(item, index) in slides" :key="index">
                <router-link :to="`/${item.type}/${item.id}`">
                    <img class="slide-img" :src="item.thumbnail" alt="Slide thumbnail" />
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.excerpt }}</p>
                </router-link>
            </div>
        </div>
        <!-- Точки-индикаторы -->
        <div class="dots">
            <span 
                v-for="(item, index) in slides" 
                :key="index" 
                :class="{ active: index === currentIndex }" 
                @click="goToSlide(index)">
            </span>
        </div>
    </div>
</template>


<script>
import axios from '../axios';
export default {
    data() {
        return {
            currentIndex: 0,
            slides: [],
            isDragging: false,
            startX: 0,
            currentTranslate: 0,
            prevTranslate: 0,
        };
    },
    methods: {
        goToSlide(index) {
            this.currentIndex = index;
        },
        startDragging(event) {
            this.isDragging = true;
            this.startX = event.type.includes("touch") ? event.touches[0].clientX : event.clientX;
            this.prevTranslate = -this.currentIndex * 100;
        },
        onDragging(event) {
            if (!this.isDragging) return;

            const currentX = event.type.includes("touch") ? event.touches[0].clientX : event.clientX;
            const diff = currentX - this.startX;
            this.currentTranslate = this.prevTranslate + (diff / this.$el.offsetWidth) * 100;

            // Обновляем стиль слайдера
            this.$refs.sliderWrapper.style.transform = `translateX(${this.currentTranslate}%)`;
        },
        endDragging() {
            if (!this.isDragging) return;

            this.isDragging = false;

            const movedBy = this.currentTranslate - this.prevTranslate;

            if (movedBy < -25) {
                this.nextSlide();
            } else if (movedBy > 25) {
                this.prevSlide();
            } else {
                this.$refs.sliderWrapper.style.transform = `translateX(-${this.currentIndex * 100}%)`;
            }
        },
        nextSlide() {
            this.currentIndex = (this.currentIndex + 1) % this.slides.length;
        },
        prevSlide() {
            this.currentIndex = (this.currentIndex - 1 + this.slides.length) % this.slides.length;
        },
    },
    async mounted() {
        try {
            const response = await axios.get('ico');
            this.slides = response.data;
        } catch (error) {
            console.error("Ошибка загрузки данных для слайдера:", error);
        }
    },
};

</script>

<style scoped>



.custom-slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.slider-wrapper {
    display: flex;
    transition: transform 0.5s ease;
}

.slide {
    flex: 0 0 100%;
    border-radius: 20px;
}

.slide h3 {
    text-align: left;
    padding-left: 10px
}

.slide p {
    text-align: left;
    padding-left: 10px
}

.slide-img {
    width: 100%;
    border-radius: 20px 20px 0 0;
    max-height: 300px;
    object-fit: cover;
}

.dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dots span {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background: #ccc;
    border-radius: 50%;
    cursor: pointer;
}

.dots span.active {
    background: #333;
}

</style>