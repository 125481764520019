<template>
  <div id="app">
    <Preloader :visible="loading" />
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <div class="info-container">
          <Coin />
        </div>
      </div>
    </nav>
    <div class="container mt-4 container-body">
      <router-view></router-view>
    </div>
    <div class="sticky-menu fixed-bottom d-flex justify-content-around p-2 bg-light">
      <div class="button-container">
        <button class="btn btn-element">
          <router-link class="nav-link" to="/">
            <img class="btn-img" src="@/assets/icons/house-solid.svg" alt=""> <span>Главная</span>
          </router-link>
        </button>
        <button class="btn btn-element">
          <router-link class="nav-link" to="/interactive">
            <img class="btn-img" src="@/assets/icons/thumbs-up-solid.svg" alt=""><span>Интерактивности</span>
          </router-link>
        </button>
        <button class="btn btn-element">
          <router-link class="nav-link" to="/rating">
            <img class="btn-img" src="@/assets/icons/rectangle-ad-solid.svg" alt=""><span>Рейтинг</span>
            </router-link>
        </button>
        <button class="btn btn-element">
          <router-link class="nav-link" to="/sponsors">
            <img class="btn-img" src="@/assets/icons/hand-solid.svg" alt=""><span>Спонсоры</span>
          </router-link>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader.vue";
import Coin from "@/components/Coin.vue";

export default {
  components: {
    Preloader,
    Coin,
  },
  data() {
    return {
      loading: false,
      userData: null,
      bonusAvailable: false,
      remainingTime: 0,
      bonusTimerInterval: null,
    };
  },
  methods: {
    initializeTelegram() {
      Telegram.WebApp.ready();
      console.log("Telegram WebApp initialized!");
      const initData = Telegram.WebApp.initDataUnsafe;
      this.userData = initData.user;

      // Отправляем данные на сервер
      fetch("https://api.crytech.pro/add_user", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          chatId: this.userData.id,
          username: this.userData.username,
          firstName: this.userData.first_name,
          lastName: this.userData.last_name,
          languageCode: this.userData.language_code,
          photoUrl: this.userData.photo_url,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.checkBonusStatus(); // Проверяем статус бонуса
          } else {
            console.error("Ошибка добавления пользователя");
          }
        })
        .catch((error) => console.error("Ошибка:", error));
    },

    checkBonusStatus() {
      fetch("https://api.crytech.pro/check_bonus_status", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ chatId: this.userData.id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            if (this.remainingTime > 0) {
              console.log("Локальный таймер ещё действует, данные сервера игнорируются.");
              return;
            }
            this.bonusAvailable = data.bonusAvailable;
            this.remainingTime = data.remainingTime;
            this.updateBonusButton();
          } else {
            console.error("Ошибка проверки статуса бонуса");
          }
        });
    },

    updateBonusButton() {
      if (this.bonusTimerInterval) {
        clearInterval(this.bonusTimerInterval);
      }

      if (this.bonusAvailable) {
        Telegram.WebApp.MainButton.text = "Получить бонус";
        Telegram.WebApp.MainButton.show();
        Telegram.WebApp.MainButton.enable();
        Telegram.WebApp.MainButton.onClick(() => this.receiveBonus());
      } else {
        this.updateTimerDisplay();

        this.bonusTimerInterval = setInterval(() => {
          if (this.remainingTime <= 0) {
            clearInterval(this.bonusTimerInterval);
            this.bonusAvailable = true;
            this.updateBonusButton();
          } else {
            this.remainingTime--;
            this.updateTimerDisplay();
          }
        }, 1000);
      }
    },

    updateTimerDisplay() {
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;
      Telegram.WebApp.MainButton.text = `Подождите ${minutes} мин ${seconds} сек`;
      Telegram.WebApp.MainButton.show();
      Telegram.WebApp.MainButton.disable();
    },

    receiveBonus() {
      fetch("https://api.crytech.pro/get_coins", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ chat_id: this.userData.id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.remainingTime = 60;
            const endTime = Date.now() + this.remainingTime * 1000;
            localStorage.setItem("bonusEndTime", endTime);

            this.bonusAvailable = false;
            this.updateBonusButton();

            // Обновляем данные о монетах через Vuex
            this.$store.dispatch("fetchUserInfo", this.userData.id);
          } else {
            console.error("Ошибка получения бонуса:", data.message);
          }
        })
        .catch((error) => console.error("Ошибка:", error));
    },
  },
  mounted() {
    const endTime = localStorage.getItem("bonusEndTime");

    if (endTime) {
      const now = Date.now();
      const remainingTime = Math.max(0, Math.floor((endTime - now) / 1000));

      if (remainingTime > 0) {
        this.remainingTime = remainingTime;
        this.bonusAvailable = false;
        this.updateBonusButton();
      } else {
        this.remainingTime = 0;
        this.bonusAvailable = true;
        this.updateBonusButton();
      }
    } else {
      this.bonusAvailable = true;
      this.updateBonusButton();
    }

    this.initializeTelegram();
    this.$store.dispatch("fetchUserInfo", this.userData.id); // Запрос информации о монетах
  },
  beforeDestroy() {
    if (this.bonusTimerInterval) {
      clearInterval(this.bonusTimerInterval);
    }
  },
};
</script>




<style>
a {
  text-decoration: none;
  color: inherit;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#app {
  background-color: #D6D6D6;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #91eae4);
  background-size: 400% 400%;
  animation: gradient-animation 15s ease infinite;
}

.info-container {
  width: 100%;
}

.navbar {
  border-radius: 0 0 20px 20px;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 100;
 }

 .button-container {
  display: flex;
  align-items: end;
  width: 100%;
  justify-content: space-between;
 }

/* Дополнительные стили */
.nav-link {
  color: #000;
  font-size: 0.5em;
}

.nav-link:hover {
  color: #0056b3 !important;
}

.container-body {
  flex:1;
}

.slider-container[data-v-62ebf35a] {
    border-radius: 20px !important;
    padding: 0 !important;
}

.element-text {
  font-size: 12px;
}
</style>



<style scoped>
.bottom-navigation {
  z-index: 999;
}

.bottom-navigation .btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  font-size: 12px;
}

.bottom-navigation .btn i {
  font-size: 24px;
}

.bottom-navigation .btn span {
  margin-top: 5px;
}

.bottom-navigation .btn:hover i {
  color: #007bff;
}

.bottom-navigation .btn:hover span {
  color: #007bff;
}

.btn-img,
.coin-img {
  width: 25px;
}

.btn-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.sticky-menu {
  position: sticky;
  bottom: 0;
  border-radius: 20px 20px 0 0;
}
</style>
